import {makeObservable, action, observable} from 'mobx';
import {StatusCodes} from 'http-status-codes';
import ReservationApi, {
  TGetReservationResponse,
  IGetReservationsResponse,
  IReservationResult,
  IProductElement,
  TGetProductsResponse,
} from '../api/reservations';
import {DateTime} from 'luxon';
import {toast} from 'react-toastify';
import type RootStore from './rootStore';
import {handleUnauthorizedResponse} from '../libs/utils';
import {t} from 'i18next';
import type {ISuperAgentError} from '../api/common';

export interface ISelectedProductAction {
  id: number;
  status: boolean;
}

interface IReservation {
  rootStore: RootStore;
  reservations: Array<IReservationResult>;
  checkInDate: string;
  next: string | null;
  previous: string | null;
  count: number;
  selectedReservation: IReservationResult | null;
  isLoading: boolean;
  isExporting: boolean;
  exportBlob: any | undefined;
  reservationProducts: Array<IProductElement>;
  qrCodeImage: string | null;
  qrCodeLoading: boolean;

  setReservations: (reservations: Array<IReservationResult>) => void;
  setReservationProducts: (reservationProducts: Array<IProductElement>) => void;
  setSelectedReservation: (reservation: IReservationResult | null) => void;
  setCheckInDate: (checkInDate: Date | string) => void;
  setIsLoading: (isLoading: boolean) => void;
  getReservations: (nextPreviousUrl?: string | null) => Promise<void>;
  getSelectedReservation: (groupId: string, checkInDate: string) => Promise<void>;
  toggleAutomaticSendCode: (
    groupId: string,
    checkInDate: string,
    currentAutoSendStatus: boolean,
    lock: string | null,
  ) => Promise<void>;
  setCount: (count: number) => void;
  setNext: (next: string | null) => void;
  setPrevious: (previous: string | null) => void;
  reset: () => void;
  setIsExporting: (isExporting: boolean) => void;
  setExportBlob: (exportBlob: any | undefined) => void;
  setSkipIdentity: (reservationId: string, isSkipped: boolean) => void;
  updateGuestContactInfo: (userId: number, email: string, phoneNumber: string) => void;
  setQRCodeImage: (imageURL: string) => void;
  getPaymentQRCode: (reservationId: string) => void;
  setQrCodeLoading: (isLoading: boolean) => void;
}

class Reservation implements IReservation {
  rootStore: RootStore;
  reservations: Array<IReservationResult> = [];
  reservationProducts: Array<IProductElement> = [];
  checkInDate: string = DateTime.utc().toLocal().toFormat('yyyy-MM-dd 06:00:00');
  next: string | null = null;
  previous: string | null = null;
  count = 0;
  selectedReservation: IReservationResult | null = null;
  isLoading = false;
  isDownloading = false;
  isExporting = false;
  exportBlob: any | undefined = undefined;
  qrCodeImage: string | null = null;
  qrCodeLoading = false;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeObservable(this, {
      reservations: observable,
      reservationProducts: observable,
      selectedReservation: observable,
      checkInDate: observable,
      next: observable,
      previous: observable,
      count: observable,
      isLoading: observable,
      exportBlob: observable,
      isDownloading: observable,
      isExporting: observable,
      qrCodeImage: observable,
      qrCodeLoading: observable,

      setReservations: action,
      setReservationProducts: action,
      setSelectedReservation: action,
      setCheckInDate: action,
      setIsLoading: action,
      getReservations: action,
      getReservationProducts: action,
      getSelectedReservation: action,
      toggleAutomaticSendCode: action,
      setNext: action,
      setPrevious: action,
      setCount: action,
      reset: action,
      setExportBlob: action,
      setIsExporting: action,
      setSkipIdentity: action,
      updateGuestContactInfo: action,
      setQRCodeImage: action,
      getPaymentQRCode: action,
      setQrCodeLoading: action,
    });
  }

  reset() {
    this.reservations = [];
    this.selectedReservation = null;
    this.checkInDate = DateTime.utc().toLocal().toFormat('yyyy-MM-dd 06:00:00');
    this.next = null;
    this.previous = null;
    this.count = 0;
  }

  setReservations(reservations: Array<IReservationResult>) {
    this.reservations = reservations;
  }

  setSelectedReservation(reservation: IReservationResult | null) {
    this.selectedReservation = reservation;
  }

  setReservationProducts(reservationProducts: Array<IProductElement>) {
    this.reservationProducts = reservationProducts;
  }

  setCheckInDate(checkInDate: Date | string) {
    if (checkInDate instanceof Date) {
      this.reset();
      this.checkInDate = DateTime.fromJSDate(checkInDate).toFormat('yyyy-MM-dd 06:00:00');
    }
  }

  setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  setNext(next: string | null) {
    this.next = next;
  }

  setPrevious(previous: string | null) {
    this.previous = previous;
  }

  setCount(count: number) {
    this.count = count;
  }

  setExportBlob(exportBlob: any | undefined) {
    this.exportBlob = exportBlob;
  }

  setIsExporting(isExporting: boolean) {
    this.isExporting = isExporting;
  }

  setQRCodeImage(imageUrl: string) {
    this.qrCodeImage = imageUrl;
  }

  setQrCodeLoading(isLoading: boolean) {
    this.qrCodeLoading = isLoading;
  }

  // Async methods
  async getSelectedReservation(groupId: string, checkInDate: string) {
    const token = this.rootStore.accountStore.user.token;

    if (token && checkInDate) {
      try {
        this.setIsLoading(true);

        const response = await ReservationApi.getReservation(token, groupId, checkInDate);
        if (response.ok) {
          const data: TGetReservationResponse = await response.body;

          this.setSelectedReservation(data);
        }
      } catch (error: any) {
        toast.error(error.message);

        if (error.status === StatusCodes.UNAUTHORIZED) {
          handleUnauthorizedResponse();
          return;
        }
      } finally {
        this.setIsLoading(false);
      }
    }
  }

  async getReservations(nextPreviousUrl: string | null = null) {
    const shortCheckInDate = DateTime.fromFormat(
      this.checkInDate as string,
      'yyyy-MM-dd 06:00:00',
    ).toFormat('yyyy-MM-dd');

    const token = this.rootStore.accountStore.user.token;
    const currentHotel = this.rootStore.hotelStore.currentHotel?.id;

    if (token && currentHotel) {
      try {
        this.setIsLoading(true);

        const response = await ReservationApi.getReservations(
          token,
          currentHotel,
          shortCheckInDate,
          nextPreviousUrl,
        );

        if (response.ok) {
          const data: IGetReservationsResponse = await response.body;

          this.setReservations(data.results);
          this.setCount(data.count);
          this.setNext(data.next);
          this.setPrevious(data.previous);
        }
      } catch (error: any) {
        this.reset();
        toast.error(error.message);

        if (error.status === StatusCodes.UNAUTHORIZED) {
          handleUnauthorizedResponse();
          return;
        }
      } finally {
        this.setIsLoading(false);
      }
    }
  }

  async toggleAutomaticSendCode(
    groupId: string,
    checkInDate: string,
    currentAutoSendStatus: boolean,
    lock: string | null,
  ) {
    const token = this.rootStore.accountStore.user.token;

    const message = currentAutoSendStatus
      ? t('reservations:disabled_auto_send_code')
      : t('reservations:enabled_auto_send_code');

    const newAutoSendStatus = !currentAutoSendStatus;

    if (token && lock) {
      try {
        const response = await ReservationApi.enableDisableAccessCode(
          token,
          groupId,
          checkInDate,
          newAutoSendStatus,
          lock,
        );

        if (response.ok) {
          toast.success(message);

          this.getReservations();
        }
      } catch (error) {
        toast.error(t('reservations:error_disabling_auto_send_code'));
      }
    }
  }

  async exportReservations() {
    const shortCheckInDate = DateTime.fromJSDate(new Date(this.checkInDate)).toFormat('yyyy-MM-dd');

    const token = this.rootStore.accountStore.user.token;
    const currentHotel = this.rootStore.hotelStore.currentHotel?.id;

    if (token && currentHotel) {
      try {
        this.setIsExporting(true);

        const response = await ReservationApi.exportReservations(
          token,
          currentHotel,
          shortCheckInDate,
        );

        if (response.ok) {
          const data = await response;
          this.setExportBlob(data.body);
        }
      } catch (error: any) {
        if (error.status === StatusCodes.UNAUTHORIZED) {
          handleUnauthorizedResponse();
          return;
        }
        toast.error(error.message);
      } finally {
        this.setIsExporting(false);
      }
    }
  }

  async getReservationProducts() {
    const token = this.rootStore.accountStore.user.token;
    const currentHotel = this.rootStore.hotelStore.currentHotel?.id;

    if (token && currentHotel) {
      try {
        this.setIsLoading(true);

        const response = await ReservationApi.getProducts(token, currentHotel);

        if (response.ok) {
          const data: TGetProductsResponse = await response.body;

          this.setReservationProducts(data);
        }
      } catch (error: any) {
        toast.error(error.message);

        if (error.status === StatusCodes.UNAUTHORIZED) {
          handleUnauthorizedResponse();
          return;
        }
      } finally {
        this.setIsLoading(false);
      }
    }
  }

  async submitProducts(
    approvedProducts: Array<ISelectedProductAction>,
    rejectedProducts: Array<ISelectedProductAction>,
  ) {
    const token = this.rootStore.accountStore.user.token;
    const currentHotel = this.rootStore.hotelStore.currentHotel?.id;

    const approvedIds = approvedProducts.map((product) => product.id);
    const rejectedIds = rejectedProducts.map((product) => product.id);

    if (token && currentHotel) {
      try {
        this.setIsLoading(true);

        const apiCalls = [];

        if (approvedIds.length > 0) {
          apiCalls.push(ReservationApi.approveProducts(token, currentHotel, approvedIds));
        }

        if (rejectedIds.length > 0) {
          apiCalls.push(ReservationApi.rejectProducts(token, currentHotel, rejectedIds));
        }

        const responses = await Promise.all(apiCalls);

        if (responses.every((response) => response.ok === true)) {
          // Reload the page
          window.location.reload();
        }
      } catch (error: any) {
        const typedError = error as ISuperAgentError;

        toast.error(typedError.response?.body?.details);

        if (rejectedIds.length > 0) {
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        }

        if (typedError.status === StatusCodes.UNAUTHORIZED) {
          handleUnauthorizedResponse();
          return;
        }
      } finally {
        this.setIsLoading(false);
      }
    }
  }

  async setSkipIdentity(reservationId: string, isSkipped: boolean) {
    const token = this.rootStore.accountStore.user.token;

    if (token) {
      try {
        this.setIsLoading(true);

        const response = await ReservationApi.setSkipIdentity(token, reservationId, isSkipped);

        if (response.ok) {
          this.getReservations();
        }
      } catch (error: any) {
        toast.error(error.message);

        if (error.status === StatusCodes.UNAUTHORIZED) {
          handleUnauthorizedResponse();
          return;
        }
      } finally {
        this.setIsLoading(false);
      }
    }
  }

  async updateGuestContactInfo(userId: number, email: string | null, phoneNumber: string | null) {
    const token = this.rootStore.accountStore.user.token;

    if (token) {
      try {
        this.setIsLoading(true);

        const response = await ReservationApi.updateGuest(token, userId, email, phoneNumber);

        if (response.ok) {
          return true;
        }
      } catch (error: any) {
        toast.error(error.message);

        if (error.status === StatusCodes.UNAUTHORIZED) {
          handleUnauthorizedResponse();
          return false;
        }
        return false;
      } finally {
        this.setIsLoading(false);
      }
    }
  }

  async getPaymentQRCode(reservationId: string) {
    const token = this.rootStore.accountStore.user.token;

    if (token) {
      try {
        this.setQrCodeLoading(true);

        const response = await ReservationApi.getPaymentQRCode(token, reservationId);

        if (response.ok) {
          const data = response;

          return data.body;
        }
      } catch (error: any) {
        toast.error(error.message);

        if (error.status === StatusCodes.UNAUTHORIZED) {
          handleUnauthorizedResponse();
          return false;
        }
        return false;
      } finally {
        this.setQrCodeLoading(false);
      }
    }
  }
}

export default Reservation;
